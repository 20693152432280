import { BIRDI_PLANS } from 'enums/plans';
import { RX_STATUS } from 'enums/prescription';

import { ProfileObjectPayload } from 'state/account/account.services';

import { ExtendedRefillRxs } from 'types/order-prescription';

interface IsBirdiSelectVisibleParams {
    item: ExtendedRefillRxs;
    profileObject: ProfileObjectPayload;
    familyPlansMap: Record<string, string>;
    isCaregiver: boolean;
    isOnDemandPlan: boolean;
}

export const handleIsBirdiSelectVisible = ({
    item,
    profileObject,
    isCaregiver,
    familyPlansMap,
    isOnDemandPlan
}: IsBirdiSelectVisibleParams) => {
    const userPlanAlias = profileObject?.planAlias;
    const epostPatientNum: string = item?.prescriptionDetail?.epostPatientNum;

    const isMembershipPlanAlias = isCaregiver
        ? Object.keys(familyPlansMap).length > 0 &&
          familyPlansMap[epostPatientNum] === BIRDI_PLANS.BRD_02 &&
          !isOnDemandPlan
        : userPlanAlias === BIRDI_PLANS.BRD_02 &&
          !isOnDemandPlan &&
          // For Rxs not included in your plan we won't be showing the birdiSelect flag
          item.prescriptionDetail.realRxCardStatus !== RX_STATUS.NOT_INCLUDED;

    return isMembershipPlanAlias && item?.prescriptionDetail?.isBirdiSelect;
};
